/**
 * Created by aodreman on 3/24/15.
 */

/****  On Resize Functions  ****/
$(window).bind("resize", function (e) {
    window.resizeEvt;
    $(window).resize(function () {
        clearTimeout(window.resizeEvt);
        window.resizeEvt = setTimeout(
            function () {
                sidebarHeight();
            },
            250
        );
    });
});

//::::::::::::::: VARIABLES :::::::::::::::::://
var exportFlag = false;

//::::::::::::::: KENDO FONTS ::::::::::::::://
kendo.pdf.defineFont({
    "Open Sans": "/fonts/OpenSans-Regular.ttf",
    "Open Sans|Italic": "/fonts/OpenSans-Italic.ttf",
    "Open Sans|Light": "/fonts/OpenSans-Light.ttf",
    "Open Sans|SemiBold": "/fonts/OpenSans-SemiBold.ttf",
    "Open Sans|Bold": "/fonts/OpenSans-Bold.ttf",
    "Open Sans|ExtraBold": "/fonts/OpenSans-ExtraBold.ttf",
    "Open Sans|Light|Italic": "/fonts/OpenSans-LightItalic.ttf",
    "Open Sans|SemiBold|Italic": "/fonts/OpenSans-SemiBoldItalic.ttf",
    "Open Sans|Bold|Italic": "/fonts/OpenSans-BoldItalic.ttf",
    "Open Sans|ExtraBold|Italic": "/fonts/OpenSans-ExtraBoldItalic.ttf"
});

// Lazy load / La pagina cargo en su totalidad
$(document).ready(function () {
    // :::: Initialize Selects ::::
    singleSelectsInit();
    multiSelectsInit();
    ColorBoxSelectsInit();
    ColorBoxmultiSelectsInit();

    dropdownHeaderBehavior();

    compareMenstrualDate();

    // Scripts a ejecutar
    showBusquedaAvanzada();

    //Before submit the form to deliver results
    deliverResults();

    // :::: SIDEBAR ::::
    toggleSidebarMenu();
    manageSidebar();

    // translateRangeCalendar();
    slider();
    changeInstitution();
    confirmDelete();
    showSections();
    showActions();
    addFormProcedure();
    addAllFormProcedure();
    deleteLastProcedure();
    addFormSteps();
    addFormSelectedStep();
    deleteLastStep();
    selectIcon();
    moveColorBox();
    //openColorBox();
    loadDatePicker();
    loadDatePickerReport();
    loadEditor();
    openWindow();
    toolTips();
    loadKendoUploadFiles();
    searchAppointment();

    addFormRequestedProcedure();
    // deleteLastRequestedProcedure();
    deleteRequestedProcedure();

    removeFilefromInput();
    loadTimePickers();
    generateBlocks();

    // :::: Scrolling Navbar ::::
    detectYOffset();
    scrollNavbar();

    // :::: Show & Hide Docent File Content  ::::
    showDocentFileContent();

    // :::: Detect Submit Buttons ::::
    submitForm();
    confirmSubmit();


    // :::: Modals ::::
    cronjobModal();
    editImageModal();

    //  :::: Cron Jobs ::::
    setCronChoice();

    // :::: RECEPTION ::::
    receptionSearchPatient();
    searchPatientOnPressingEnterKey();
    modalScanFile();
    searchRepresantative();
    changeProceduresReception();

    // :::: MASKED INPUTS ::::
    initMaskedInputs();

    // :::: KENDO SCHEDULER :::: kendoScheduler(); ShowHideScheduleres() ::::
    // APPOINTMENTS ::::
    rejectAppointment();

    // :::: KENDO GRID ::::
    clearKendoGridFilters();
    GridMemoryON();
    GridRefreshTab();

    // :::: ORDERS ::::
    changeOrderStatusOrBlock();

    // :::: ORDERS ON RADIOLOGIST ::::
    changeOrderStatusOrBlockRadiologist();
    insertTemplate();
    checkToApproveOrderRadiologist();
    checkToRejectOrderRadiologist();

    // :::: PRINT BUTTONS ::::
    printButtons();

    // :::: PATIENT HISTORY ::::
    paginationPatientHistory();

    // :::: REWRITE ORDER ::::
    rewriteTechnicianOrder();

    // :::: REVERT ORDER ::::
    revertRadiologistOrder();

    // :::: PRE-ADMISSION ::::
    approvePreadmissionOrder();

    // :::: SAVE DRAFT ::::
    saveDraft();

    // :::: SEARCH ::::
    changeSearchColumns();

    // :::: Valid the num plates greather than 0 is the checkbox is checked ::::
    validNumCd();
    validNumPlates();

    // :::: REPORTS ::::
    closeColorBox();
    // :::: SEARCH ::::
    // :::: DATE PICKERS ::::
    if (typeof readonlyDatePickers == "function") {
        readonlyDatePickers();
    }

    // :::: DROPDOWN LISTS ::::
    if (typeof defaultPregnancyStatusForMale == "function") {
        setTimeout(
            function () {
                defaultPregnancyStatusForMale();
            },
            100
        );
    }

    // :::: SET KENDO DATEPICKERS ::::

    setKendoDatePickers();

    //initialize the pacs call
    Pac.init();

    //initialize dictation audio
    DictationAudio.init();

    //Disable DatePickers Input
    disableDatePicker();

    //Sets the reception table filters for today's date and orders them by time
    setReception();

    //sets the value on the urgent checkbox to 1 or 0
    checkboxUrgent();

    //Manage the anonymous function
    setAnonymous();

    //Sets the fields of servicerequest not fillable when the check "Anonymous" is checked
    anonymousAction();

    validateRoomTimes();

    colorPickerLoad();
});

function assignOrder(id){
    $('#orderId').val(id);

    $('#radiologistOrder').selectpicker({size: 5 })
        .selectpicker('val', '');

    $('#user-change-sel').selectpicker({size: 5, dropupAuto: false});
    ImpersonateUser.userId = null;
    ImpersonateUser.password = null;

    $('#assignOrderModal-error').remove();
    $('#assignOrderModal').modal('show');
}

function colorPickerLoad(){
    $(".pickercolor").kendoColorPicker({
        input: true,
        preview:true,
        value: "#989898",
        buttons: false,
        views: ["gradient"]
    });
}

function anonymousAction(){
    $('#reason').closest('.col-xs-12').hide();
    $('#anonymous-chk').on('change', function(){
        if($('#anonymous-chk').prop('checked') == true){
            console.log('true');
            $('#reason').closest('.col-xs-12').show();
            $('#patient_ID,#first_name,#last_name,#email,#telephone_number_masked,#birth_date,#country_id,#citizenship,#cellphone_number_masked,#responsable_id,#responsable_name,#occupation,#address').closest('.col-xs-12').hide();

        }else{
            console.log('false');
            $('#reason').closest('.col-xs-12').hide();
            $('#patient_ID,#first_name,#last_name,#email,#telephone_number_masked,#birth_date,#country_id,#citizenship,#cellphone_number_masked,#responsable_id,#responsable_name,#occupation,#address,#weight,#height').closest('.col-xs-12').show();

        }
    });
}

function setAnonymous(){
    $('#anonymous-chk').on('change', function(){
        if($('#anonymous-chk').prop('checked') == true){
            $('#anonymous-chk').val(1);
        }else{
            $('#anonymous-chk').val(0);
        }
    });
}

function validateRoomTimes(){
    $('#end_hour').on('change', function(e){
        //start time
        var start_time = $("#start_hour").val();

        //end time
        var end_time = $("#end_hour").val();

        //convert both time into timestamp
        var stt = new Date("November 13, 2013 " + start_time);
        stt = stt.getTime();

        var endt = new Date("November 13, 2013 " + end_time);
        endt = endt.getTime();

        //by this you can see time stamp value in console via firebug
        console.log("Start: "+ stt + " End: " + endt);

        if(endt < stt) {
            $("#start_hour").val($("#end_hour").val());
            kendo.alert(WrongRoomEndHour());

        }
    });
}
function checkboxUrgent(){
    $('#urgent').on('change', function(){
        if($('#urgent').prop('checked') == true){
            $('#urgent').val(1);
        }else{
            $('#urgent').val(0);
        }
    });
}


function setReception() {
    if($("div").hasClass( "reception index" )){

        var date = new Date();
            date.setHours(0,0,0,0);

        // Give the controll to filter to the API

        // Filter
        $("#grid").data("kendoGrid").dataSource.filter({
            logic: "and",
            filters: [{
                field: "date",
                operator: "eq",
                value: date
            }]
        });
        // Sort
        $("#grid").data("kendoGrid").dataSource.sort({
            field: "dateStartTime",
            dir: "asc"
        });

    }
}

function dropdownHeaderBehavior() { //funcionamiento de los dropdown del header
    $('li.dropdown.config-header a').on('click', function (event) {
        $('li.dropdown.config-header a').not(this).parent().removeClass('open');

        if ($(this).parent().hasClass('open')) {
            $(this).parent().removeClass('open');
        } else {
            $(this).parent().toggleClass('open');
        }
    });

    $('body').on('click', function (e) {
        if (!$('li.dropdown.config-header').is(e.target)
            && $('li.dropdown.config-header').has(e.target).length === 0
            && $('.open').has(e.target).length === 0
        ) {
            $('li.dropdown.config-header').removeClass('open');
        }
    });
}

// Muestra el div de la busqueda avanzada en la pagina busqueda.html
function showBusquedaAvanzada() {
    $("#b_avanzada_btn").on("click", function () {
        $("#b_avanzada").toggle();
    });
}

// Debido a un problema con la traduccion del plugin datarangepicker se hace la
// traduccion aqui
function translateRangeCalendar() {
    var control = $(".ranges").find("ul");
    control.find("li").each(function () {
        var current = $(this);
        switch (current.text()) {
            case "Today":
                current.text("Hoy");
                break;

            case "Yesterday":
                current.text("Ayer");
                break;

            case "Last 7 Days":
                current.text("Ultimos 7 dias");
                break;

            case "Last 30 Days":
                current.text("Ultimos 30 dias");
                break;

            case "This Month":
                current.text("Este mes");
                break;

            case "Last Month":
                current.text("Mes pasado");
                break;

            default:
                break;
        }
    });
}

// Rounded-up value for percentage animation
function commaSeparateNumber(val) {
    while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
    return val;
}

function slider() {
    /*$(".fullwidthbanner").css("display","block");
     $('.fullwidthbanner').revolution(
     {
     delay:9000,
     startheight:490,
     startwidth:890,

     hideThumbs:200,

     thumbWidth:100,
     thumbHeight:50,
     thumbAmount:5,

     navigationType:"none",
     navigationArrows:"verticalcentered",
     navigationStyle:"navbar",
     touchenabled:"on",
     onHoverStop:"on",

     navOffsetHorizontal:0,
     navOffsetVertical:20,

     shadow:1,
     fullWidth:"on"

     });*/
}

function changeInstitution() {
    $("#dropdown-institutions").change(function () {
        resetNoReadMessages();
        window.location.href = this.value;
    });
}
function compareMenstrualDate() {
    $('input[name=last_menstrual_date]').change(function() {

        var from = $("#last_menstrual_date").val().split("-");

        var lastDate = new Date(from[2], from[1] - 1, from[0]);

        var currentDate = new Date();

        if(lastDate > currentDate) {

            var message = DateBiggerThanNow();
            $("#last_menstrual_date").val('');
            kendo.alert(message);
            return false;
        }

    });
}
function showSections() {
    $(".institution-legend").click(function () {
        var id = this.id;
        var strings = id.split("-");
        if ($("#sections-" + strings[1]).is(":visible")) {
            $("#sections-" + strings[1]).hide();
            $("#" + this.id + " .fa").removeClass("fa-caret-down");
            $("#" + this.id + " .fa").addClass("fa-caret-right");
        } else {
            $("#sections-" + strings[1]).show();
            $("#" + this.id + " .fa").removeClass("fa-caret-right");
            $("#" + this.id + " .fa").addClass("fa-caret-down");
        }
    });
}

function showActions() {
    $(".section-legend").click(function () {
        var id = this.id;
        var strings = id.split("-");
        if ($("#actions-" + strings[1]).is(":visible")) {
            $("#actions-" + strings[1]).hide();
            $("#" + this.id + " .fa").removeClass("fa-caret-down");
            $("#" + this.id + " .fa").addClass("fa-caret-right");
        } else {
            $("#actions-" + strings[1]).show();
            $("#" + this.id + " .fa").removeClass("fa-caret-right");
            $("#" + this.id + " .fa").addClass("fa-caret-down");
        }
    });
}

function changeProceduresReception() {
    $("#modality_id_reception").change(function () {
        var modality = $(this).val();
        selectFilterBy('#procedure_id', 'data-modality-id', modality);
    });
}

function changeProceduresReceptionMulti() {
    $("#modalities").change(function () {
        var modality = $(this).val();
        console.log(modality);
        selectFilterMultiBy('#procedure', 'data-modality-id', modality);
      /*  $.each(modality, function( index, value ) {
            console.log('hiola',value);
            selectFilterMultiBy('#procedure', 'data-modality-id', value);
          });*/
        //selectFilterBy('#procedure', 'data-modality-id', modality);
    });
}

function addFormRequestedProcedure() {
    $("#addRequestedProcedure").click(function () {
        var value = $("#procedure_id").val();
        var randomID = randomString(12);
        var radiologo =$("#radiologist").val();
        if (value) {
            $.get("/requestedprocedures/form/" + randomID + "/" + value + "/" + radiologo + "/", function (data) {
                $("#requestedProcedures").hide().append(data).fadeIn(400);
            });

            // selectRemoveValue('#procedure_id', value);
            selectFirst('#modality_id_reception');
            $("#radiologist").selectpicker('val', 'null');
        } else {
            errorAddProcedure();
        }
    });
}

function addFormSteps() {
    $("#legend-add-procedure").click(function () {
        var randomID = randomString(12);

        var size = $(".step").size();

        $.get("/steps/form/" + size + "/" + randomID, function (data) {
            $("#steps").hide().append(data).fadeIn(400);
            $("select#modality_id").selectpicker();
            $(
                "#collapse_" +
                (size + 1) +
                " select#equipment, #collapse_" +
                (size + 1) +
                " select#consumables"
            ).kendoMultiSelect({autoClose: false});
        });

        sumStep();
    });
}

function addFormSelectedStep() {
    $("#legend-add-created-procedure").click(function () {
        var randomID = randomString(12);

        var value = $("select#step-selected").val();

        var size = $(".step").size();

        if (value) {
            $.get("/steps/form/" + size + "/" + randomID + "/" + value, function (data) {
                $("#steps").hide().append(data).fadeIn(400);
                $("select#modality_id").selectpicker();
                $(
                    "#collapse_" +
                    (size + 1) +
                    " select#equipment, #collapse_" +
                    (size + 1) +
                    " select#consumables"
                ).kendoMultiSelect({autoClose: false});
            });

            //$('select#step-selected').val("");
            $("select#step-selected option").each(function () {
                if ($(this).val() == value) {
                    $(this).hide();
                }
            });

            sumStep();
        } else {
            errorAddStep();
        }
    });
}

function deleteRequestedProcedure() {
    $("a.requested-procedure-close").click(function () {
        var delete_id = $(this).attr("id-attr");

        var position = parseInt($("#" + delete_id).attr("position"));

        $("#" + delete_id).remove();

        var size = $(".step").size();

        $("#steps-count").text(size);

        $(".step-position").each(function () {
            var order = parseInt($(this).text());
            if (order > position) {
                $(this).text(order - 1);
            }
        });

        $(".step-container").each(function () {
            var order = parseInt($(this).attr("position"));
            if (order > position) {
                $(this).attr("position", order - 1);
            }
        });

        $(".step-order").each(function () {
            var order = parseInt($(this).val());
            if (order > position) {
                $(this).val(order - 1);
            }
        });
    });
}

function deleteLastStep() {
    /*$('#legend-delete-procedure').click(function() {

     var value = $('.step:last-child input#step-id').val();


     if (!(typeof value === 'undefined')) {
     $('select#step-selected option').each(function () {
     if ($(this).val() == value) {
     $(this).show();
     }
     });
     }

     $('.step:last-child').fadeOut(function () {

     $(this).remove();

     if ($('.step').size() == 0) {
     $('#legend-delete-procedure').hide();
     }

     });

     substractStep();


     });*/
}

function sumStep() {
    var step_sum = $("#steps-count").contents().filter(function () {
        return this.nodeType == 3;
    }).text();

    var step_sum = parseInt(step_sum) + 1;

    $("#steps-count").text(step_sum);
}

function substractStep() {
    var step_sum = $("#steps-count").contents().filter(function () {
        return this.nodeType == 3;
    }).text();

    var step_sum = parseInt(step_sum) - 1;

    $("#steps-count").text(step_sum);
}

function selectIcon() {
    $("#list-icon a").click(function () {
        $("#btn-icon").html($(this).text() + '<span class="caret"></span>');
        $("input#icon").val($(this).attr("data-text"));
    });
}

//-----Open the color-box------//
function openColorBox() {
    $("a.edit-row.cboxElement").colorbox({
        transition: "none",
        opacity: 0.5,
        "background-color": "#000",
        fastIframe: false,
        width: 600,
        maxHeight: 600,
        //'innerHeight': 400,
        scrolling: false,
        close: "<span>x</span>",
        className: "fancy-shadow",
        overlayClose: false
    });

    $("a.add-row").colorbox({
        transition: "none",
        opacity: 0.5,
        "background-color": "#000",
        fastIframe: false,
        width: 600,
        maxHeight: 600,
        //'innerHeight': 400,
        scrolling: false,
        close: "<span>x</span>",
        className: "fancy-shadow",
        overlayClose: false
    });

    var body = document.getElementById("app-layout");

    $(document).bind("cbox_complete", function () {
        body.className += " modal-open";
    });

    $(document).bind("cbox_closed", function () {
        body.className = "";
        body.className += " pace-done";
    });
}

function readURL(input, imageID) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $(imageID).attr("src", e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
    }
}

/****  Custom Select Inputs  ****/
function singleSelectsInit() {
    if ($("select").length && $.fn.selectpicker) {
        setTimeout(
            function () {
                $("select")
                    .not("select[multiple], .k-formatting.k-decorated")
                    .selectpicker();
            },
            50
        );
    }
}

function multiSelectsInit() {
    if ($("select[multiple]").length) {
        setTimeout(
            function () {
                $("select[multiple]").kendoMultiSelect({
                    autoClose: false,
                    noDataTemplate: ""
                });
            },
            50
        );
    }
}

function ColorBoxSelectsInit() {
    if ($("#colorbox select").length && $.fn.selectpicker) {
        setTimeout(
            function () {
                $("#colorbox select").not("select[multiple]").selectpicker();
            },
            50
        );
    }
}

function ColorBoxmultiSelectsInit() {
    if ($("#colorbox select[multiple]").length) {
        setTimeout(
            function () {
                $("#colorbox select[multiple]").kendoMultiSelect({
                    autoClose: false,
                    noDataTemplate: ""
                });
            },
            50
        );
    }
}

function detectYOffset() {
    window.onscroll = function (e) {
        scrollNavbar();
    };
}

function scrollNavbar() {
    var window_top = window.scrollY;
    var action_bar_hidden = $("#scroll-action-bar-hidden");
    var action_bar = $("#scroll-action-bar");

    if (window_top > 70) {
        action_bar.addClass("opacity-hide");
    } else {
        action_bar.removeClass("opacity-hide");
    }

    if (window_top > 70) {
        action_bar_hidden.addClass("opacity-show");
    } else {
        action_bar_hidden.removeClass("opacity-show");
    }
}

function confirmSubmit() {
    ImpersonateUser.init();
    KillUserSession.init();
    $("#searchSubmit, .searchSubmit").click(function (e) {
        console.log('clicked');
        var form_id = $(this).attr("formtarget");
        var route = $(this).attr("href");
        var tag_name = $(this).prop("tagName");
        var _this = $(this);
        if( window.location.pathname.includes("/search/edit/") ){
            kendo.confirm(NoReverse()).then(function (e) {
                kendo.ui.progress($(document.body),true);
                if ( tag_name == "BUTTON") {
                    if ( ImpersonateUser.canSubmit( _this ) ) {
                        ImpersonateUser.injectUser( $(form_id) );
                        $(form_id).trigger("submit");
                    }
                } else {
                    window.location.href = route;
                }
            }, function () {

            });
        }

    });
}

function submitForm() {

    ImpersonateUser.init();

    $("#submit-1, #submit-2").click(function (e) {

        var form_id = $(this).attr("formtarget"),
            route   = $(this).attr("href"),
            _this   = $(this);

        //kendo.ui.progress($(document.body),true);
        if ($(this).hasClass("add-row") || $(this).hasClass("edit-row")) {
            openColorBox();
        } else if ($(this).prop("tagName") == "BUTTON") {
            //if ( ImpersonateUser.canSubmit( $(this) ) ) {
            if ( ( ImpersonateUser.canSubmit( $(this) ) ) && ( KillUserSession.canSubmit( $(this) ) ) ) {
                ImpersonateUser.injectUser( $(form_id) );
                KillUserSession.injectKillSession( $(form_id) );
                // Default Disable
                _this.prop("disabled", true);
                // New Invalid Handler (jqueryvalidation, overwrite)
                var newInvalidHandler = function(form, validator) {

                    // Call Previous Handler
                    validator.settings.invalidHandler(form, validator);

                    if (validator.numberOfInvalids()) {
                        _this.prop("disabled", false);
                    }

                };
                $(form_id).off('invalid-form.validate')
                          .on('invalid-form.validate', newInvalidHandler);
                // Trigger Event
                $(form_id).trigger("submit");
            }
        } else {
            window.location.href = route;
        }

    });

    $("#prodecure-add-form, #procedure-edit-form").on("submit", function (e) {

        var steps = $("#steps");

        if ($.trim(steps.text()).length == 0 && steps.children().length == 0) {

            var message = error5001();

            kendo.alert(message);

            return false;
        }

        return true;

    });
}

/**
 * @fecha 27-06-2017
 * @programador Hendember Heras
 * @objetivo Hace el cambio temporal de usuario al hacer submit a un form.
 *
 canSubmit: Verifica si se puede hacer submit o no, dependiendo si se cambió el usuario,
 y si ya se introdujo el password.
 recibe como parámetro el botón al que se hace click para enviar el formulario
 injectUser: Agrega dos campos hidden al formulario con el id de usuario y password
 recibe como parámetro el formulario al cual se le agregarán los campos de autentación
 */
var ImpersonateUser = {
    ready       : false,
    sel1        : $('#user-change-sel'),
    sel2        : $('#user-change-sel2'),
    modal       : $('#login-modal'),
    userId      : null,
    password    : null,
    callbackBtn : null,
    init        : function() {
        if ( this.sel1.length == 1 && !this.ready ) {
            //listen for login form submitting
            $('#login-form').submit( function(e) {
                e.preventDefault();

                ImpersonateUser.userId  = $('#user'     , ImpersonateUser.modal).val();
                ImpersonateUser.password = $('#password'    , ImpersonateUser.modal).val();

                ImpersonateUser.sel1.selectpicker( 'val', ImpersonateUser.userId );
                ImpersonateUser.sel2.selectpicker( 'val', ImpersonateUser.userId );

                if ( ImpersonateUser.userId != '' && ImpersonateUser.password != '' ) {
                    ImpersonateUser.modal.modal('hide');
                    ImpersonateUser.callbackBtn.click();
                }

                return false;
            });
            //keep both selects having the same value
            this.sel1.change( function() {
                ImpersonateUser.sel2.selectpicker( 'val', $(this).val() );
            })
            this.sel2.change( function() {
                ImpersonateUser.sel1.selectpicker( 'val', $(this).val() );
            })

            this.ready = true;
        }
    },
    canSubmit   : function( button ) {
        this.callbackBtn = button;

        if ( this.ready ) {
            var hasChange = !this.sel1[0].options[ this.sel1[0].selectedIndex ].defaultSelected;
            var havePassword = ( $('#user', this.modal).val() == this.sel1.val() ) &&  this.password != null;

            if ( hasChange && !havePassword ) {
                $('#user'       , this.modal).selectpicker( 'val', this.sel1.val() );
                $('#password'   , this.modal).val('');
                this.userId     = null;
                this.password   = null;

                this.modal.modal('show');

                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    },
    injectUser  : function( form ) {
        if ( this.ready ) {
            if ( $('#user-change', form).length == 0 ) {
                var hiddenUser = $('<input/>',{type:'hidden', id:'user-change', name:'user-change' });
                var hiddenPass = $('<input/>',{type:'hidden', id:'user-change-password', name:'user-change-password' });

                form.append(hiddenUser)
                    .append(hiddenPass);
            }

            $('#user-change', form).val( this.userId );
            $('#user-change-password', form).val( this.password );
        }
    }
}
/**
 * @fecha 09-11-2017
 * @programador JCH
 * @objetivo Realizar el destroy de todas las sesiones al momento de realizar algun cambio de configuracion
 *
 canSubmit: Verifica si se puede hacer submit o no, dependiendo si se cambió el usuario,
 y si ya se introdujo el password.
 recibe como parámetro el botón al que se hace click para enviar el formulario
 injectUser: Agrega dos campos hidden al formulario con el id de usuario y password
 recibe como parámetro el formulario al cual se le agregarán los campos de autentación
 */
var KillUserSession = {
    hidden      : $('#kill-session'),
    ready       : false,
    callbackBtn : null,
    init        : function() {
        if ( this.hidden.length == 1 && !this.ready ) {
            //listen for login form submitting

            //this.confirm = alertConfirm(this.functionTrue, this.functionFalse,"pruebita", "prueba");

            this.ready = true;
        }
    },
    canSubmit   : function( button ) {
        this.callbackBtn = button;

        if ( this.ready ) {

            if ( this.hidden.length == 1 && this.hidden.val() == 0 ) {
                alertConfirm(this.functionTrue, this.functionFalse, killSessionMessage(), confirmMessage()).open();
                //this.confirm.open();
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    },
    injectKillSession  : function( form ) {
        if ( this.ready ) {
            if ( $('#user-change', form).length == 0 ) {
                var hiddenKiller = $('<input/>',{type:'hidden', id:'kill-session', name:'kill-session' });

                form.append(hiddenKiller);
            }

            $('#kill-session', form).val( 1 );
        }
    },
    functionTrue : function () {
        KillUserSession.hidden.val(1);
        KillUserSession.callbackBtn.click();
    },
    functionFalse : function () {
    },
}

function alertConfirm(functionTrue,functionFalse,content,title){
    return $("<div class='panel-body '></div>").kendoAlert({
        content: content,
        //width: '800px',
        title:title,
        primary: true,
        actions:  [{
            text:yesConfirm(),
            action: functionTrue,
        },
        {
            text: noConfirm(),
            action: functionFalse,
        }]
    }).data("kendoAlert");
}

function cronjobModal() {
    $(
        "#appointment-expression, #mammography-expression, #birthday-expression"
    ).on("click", function (e) {
        var cron_type = "#" + $(this).attr("id");

        $(".modal #cron-type").attr("value", cron_type);
        $("#config-modal").modal("show");
    });
}

function setCronChoice() {
    var oldChoice, joldChoice, firstTime = 0;

    $(
        "#config-modal #minutes, #config-modal #hours, #config-modal #days, #config-modal" +
        " #months, #config-modal #dow, #config-modal #miscellaneous"
    ).on("change", function (e) {
        if (firstTime == 0) {
            oldChoice = this;
            joldChoice = $(this);
            firstTime = 1;
        } else if (firstTime == 1 && oldChoice != this) {
            joldChoice.selectpicker("val", "");
            joldChoice = $(this);
            oldChoice = this;
        }

        var cron = $(this).val();
        $(".modal #cron-finished").attr("value", cron);
    });

    $("#config-modal").on("hidden.bs.modal", function (e) {
        if (joldChoice != undefined) {
            joldChoice.selectpicker("val", "");
            oldChoice = undefined;
            joldChoice = undefined;
            firstTime = 0;
        }
    });

    $("button#set-cronjob").on("click", function (e) {
        var cron_type = $(".modal #cron-type").val();
        var command = $(".modal #command").val();
        var cronjob = $(".modal #cron-finished").val() +
            " " +
            (command == "" ? "?" : command);

        $(cron_type).attr("value", cronjob);

        $(".modal #cron-type").attr("value", "");
        $(".modal #command").attr("value", "");
        $(".modal #cron-finished").attr("value", "");

        $("#config-modal").modal("hide");
    });
}
function disableDatePicker(){
    //$('#birth_date, #culmination_date, #last_menstrual_date, #death_date').attr("readonly", true);
    $('#culmination_date, #last_menstrual_date, #death_date').attr("readonly", true);
}
function loadDatePicker() {
    $('input[type="date"]').kendoDatePicker({format: getFormatDate()});
    // $('#birth_date').kendoDatePicker({   format: "yyyy-MM-dd" });
    // $('#death_date').kendoDatePicker({   format: "yyyy-MM-dd" });
    // $('#issue_date').kendoDatePicker({   format: "yyyy-MM-dd" });
    // $('#last_menstrual_date').kendoDatePicker({  format: "yyyy-MM-dd" });
}

function loadDatePickerReport() {
    $('input[type="date"]').kendoDatePicker({

    });
    // $('#birth_date').kendoDatePicker({ 	format: "yyyy-MM-dd" });
    // $('#death_date').kendoDatePicker({ 	format: "yyyy-MM-dd" });
    // $('#issue_date').kendoDatePicker({ 	format: "yyyy-MM-dd" });
    // $('#last_menstrual_date').kendoDatePicker({ 	format: "yyyy-MM-dd" });
}


function setKendoDatePickers() {
    $("#birth_date, #death_date, #last_menstrual_date").kendoDatePicker({});
}

function loadDateTimePicker() {
    $('input[type="dateTime"]').kendoDateTimePicker({
        format: 'dd-MM-yyyy HH:mm',
        timeFormat: "HH:mm",
        parseFormats: ["yyyy-MM-dd HH:mm:ss"]
    });
}

function transformDate() {
    $("input[data-datetype]").each(function () {
        var dateType = $(this).data('datetype');
        if (dateType == "dateTime") {
            var dateTimePicker = $(this).data("kendoDateTimePicker");
            var value = dateTimePicker.value();
            $(this).val(kendo.toString(value, "yyyy-MM-dd HH:mm:ss"));
        } else if (dateType == "date") {
            var datePicker = $(this).data("kendoDatePicker");
            var value = datePicker.value();
            $(this).val(kendo.toString(value, "yyyy-MM-dd"));
        }
    });
}

function loadDateTimePicker() {
    $('input[type="dateTime"]').kendoDateTimePicker({
        format: 'dd-MM-yyyy HH:mm',
        timeFormat: "HH:mm",
        parseFormats: ["yyyy-MM-dd HH:mm:ss"]
    });
}

function transformDate() {
    $("input[data-datetype]").each(function () {
        var dateType = $(this).data('datetype');
        if (dateType == "dateTime") {
            var dateTimePicker = $(this).data("kendoDateTimePicker");
            var value = dateTimePicker.value();
            $(this).val(kendo.toString(value, "yyyy-MM-dd HH:mm:ss"));
        } else if (dateType == "date") {
            var datePicker = $(this).data("kendoDatePicker");
            var value = datePicker.value();
            $(this).val(kendo.toString(value, "yyyy-MM-dd"));
        }
    });
}

function onPreviewClick (e) {
    $("#editor").kendoEditor();
    var editor = $(this).data("kendoEditor");
    var editorContent = editor.value();
    var popupHtml =
        '<div id="previewEditor" class="k-editor-dialog k-popup-edit-form k-edit-form-container" style="width:auto;">' +
        '<div style="padding: 0 1em;">' +editorContent+

        '</div>' +
        '<div class="k-edit-buttons k-state-default">' +
        '<button class="k-dialog-insert k-button k-primary">Imprimir</button>' +
        '</div>' +
        '</div>';


    // Store the Editor range object.
    // Needed for IE.
    var storedRange = editor.getRange();

    // Create a modal Window from a new DOM element.
    var popupWindow = $(popupHtml)
        .appendTo(document.body)
        .kendoWindow({
            // Modality is recommended in this scenario.
            modal: true,
            width: 800,
            resizable: false,
            title: "Vista Preliminar",
            // Ensure the opening animation.
            visible: false,
            // Remove the Window from the DOM after closing animation is finished.
            deactivate: function(e){ e.sender.destroy(); }
        }).data("kendoWindow")
        .center().open();

// Insert the new content in the Editor when the Insert button is clicked.
    popupWindow.element.find(".k-dialog-insert").click(function(){
        editor.exec("print");
    });
}



function loadEditor() {
    //$("#template").kendoEditor({resizable: {content: false, toolbar: true}});
    var defaultTools = kendo.ui.Editor.defaultTools;
    //defaultTools["insertParagraph"].options.shift = false;
    delete defaultTools["insertParagraph"].options;
    $("#template").kendoEditor({
        tools: ["fontSize","bold", "italic", "underline",
            "justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "insertUnorderedList",
            "insertOrderedList", "indent", "outdent",
             "createTable"],
        keydown: function(e) {
            /* The result can be observed in the DevTools(F12) console of the browser. */
            if(e.key === "F1") {
                e.preventDefault();
                data=$("#audio").attr("src");
                if (data!='') {
                    $("#audio")[0].play();
                }

            }
            if(e.key === "F2") {
                e.preventDefault();
                data=$("#audio").attr("src");
                if (data!='') {
                    var audio = document.getElementById('audio');
                    if (audio.paused == false) {
                        audio.pause();
                    } else {
                        audio.play();
                    }
                }
            }
            if(e.key === "F3") {
                e.preventDefault();
                data=$("#audio").attr("src");
                if (data!='') {
                    var audio = document.getElementById('audio');
                    var currentTimeS = audio.currentTime;
                    audio.currentTime = currentTimeS - 1;
                    audio.play();
                }
            }
            if(e.key === "F4") {
                e.preventDefault();
                data=$("#audio").attr("src");
                if (data!='') {
                    var audio = document.getElementById('audio');
                    var currentTimeS = audio.currentTime;
                    audio.currentTime = currentTimeS + 1;
                    audio.play();
                }

            }
        }
    });

}

function openWindow() {
    $("a.openFancy").click(function (e) {
        e.preventDefault();
        /*var url = $(this).attr('href');

         var window = $('#windowFancy').kendoWindow({
         width:'auto',
         height:'auto',
         title:'Pascual',
         content:url
         });

         window.data('kendoWindow').open();*/
    });
}

/**
* @fecha 31-07-2017
* @programador Hendember Heras
* @objetivo Abre una ventana modal con las imágenes del pacs.
*
init: Inicia el listener para los botones con la clase 'btn-pac'
canOpen: Hace un request a un endpoint que verifica si se tienen los datos necesarios
        para hacer la llamada, y de ser así devuelve el url ya listo para ser usado.
open: Abre el modal con el url obtenido en canOpen
*/
var Pac = {
    init: function() {
        $(document).on('click','.btn-pac', function(e){
                e.preventDefault();

                Pac.canOpen( $(this) );
            });
    },
    canOpen: function( btn ) {
        var url = btn.attr('href');

        $.ajax({
            url: url,
            type: "GET",
            success: function ( data ) {
                if ( data.success ) {
                    Pac.open(data.url);
                } else {
                    kendo.alert(data.message);
                }
            },
            error: function (xhr, responseText, response) {
                kendo.alert(responseText);
            }
        });
    },
    open: function( url ) {
        /* opens inside page (iframe)
        $(document.body).append('<div id="pac-windows"></div>');

        $("#pac-windows").kendoWindow({
            width: "50%",
            height: "50%",
            actions: [ "Close", "Refresh", "Minimize", "Maximize" ]
        });

        var win = $("#pac-windows").data("kendoWindow");

        win.center()
           .open()
           .refresh(url);*/

        /* opens in new window */
        var windowName = "pacs";
        var widthP = 0.5;
        var heightP = 0.5;

        var width = screen.width * widthP;
        var height = screen.height * heightP;

        var left  = (screen.width-width)/2;
        var top   = (screen.height-height)/2;

        var options = "width="+width+", height="+height+", top="+top+", left="+left;

        window.open(url, windowName, options);
    }
};

function moveColorBox() {
    $("#colorbox").insertAfter("#main-content");
    $("#cboxOverlay").insertBefore("#colorbox");
}

function toolTips() {
    $('[data-toggle="tooltip"]').tooltip();
}

/*
 function uploadPatientImage() {

 $('#set-image').on('click',function (e) {
 e.preventDefault();

 try {
 // other modern browsers
 formData = new FormData(that.form[0]);
 } catch(e) {
 // IE10 MUST have all form items appended as individual form key / value pairs
 formData = new FormData();
 formData.append('img', that.form.find("input[type=file]")[0].files[0]);

 }

 $.ajax({
 method: 'POST',
 url: $(this).attr('action'),
 data: $('#editable-img').attr('src'),
 //contentType: 'application/x-www-form-urlencoded',
 dataType: 'JSON',
 beforeSend: function (xhr) {
 var token = $('input[name="_token"]').attr('value');
 if (token) {
 return xhr.setRequestHeader('X-CSRF-TOKEN', token);
 }
 },
 success: function (r) {
 console.log(r);
 },
 error: function (r) {
 console.log(r);
 }
 });

 });

 }
 */

function editImageModal() {

    var cropper, cropStatus = false, target = null;

    var elementId = $("#img-modal .modal-body").find(".croppic-init").attr("id");

    var croppic_init_callback = function () {
        if ($("#signature-img-container")) {
            $("#signature-img-container_imgUploadField").attr("accept", "image/png");
        }
    }

    $("#img-modal").on("show.bs.modal", function (e) {
        //remove all classes ( the original croppic-init plus any other added later with '-dimensions')
        $('#'+elementId).removeClass();

        //add the dimensions class for the target
        if ( target ) {
            $('#'+elementId).addClass(target + '-dimensions');
        }

        //add again the original class (croppic-init)
        $('#'+elementId).addClass('croppic-init');

        initCroppic(elementId);
    });

    $("#img-modal").on("hidden.bs.modal", function (e) {
        cropper.destroy();
        cropStatus = false;
    });

    $(".do-crop").on("click", function(e) {
        target = $(this).attr('target');
        $("#img-modal").modal("show");
    });

    $("#edit-photo").on("click", function (e) {
        $("#img-modal").modal("show");
    });

    $("#set-photo, #set-signature, #set-logo").on("click", function () {
        if (!cropStatus) {
            cropper.crop();
        }

        $("#img-modal").modal("hide");
    });

    function initCroppic(elementId) {

        var croppicOptions = {
            uploadUrl: "/img_save_to_file.php",
            cropUrl: "/img_crop_to_file.php",
            modal: false,
            imgEyecandyOpacity: 0.4,
            loaderHtml: '<div class="loader bubblingG"><span id="bubblingG_1"></span><span id="bubblingG_' +
            '2"></span><span id="bubblingG_3"></span></div> ',
            onBeforeImgUpload: function () {
                console.log("onBeforeImgUpload");
            },
            onAfterImgUpload: function (data) {
                console.log("onAfterImgUpload");
                console.log(data);
            },
            onImgDrag: function () {
                console.log("onImgDrag");
            },
            onImgZoom: function () {
                console.log("onImgZoom");
            },
            onBeforeImgCrop: function (data) {
                console.log("onBeforeImgCrop");
                cropStatus = true;
            },
            onAfterImgCrop: function (data) {
                console.log("onAfterImgCrop");
                console.log(data);

                if (data.status == "success") {
                    $("#photo").attr("value", data.url);
                    $("#avatar").attr("src", data.url);
                    $("#signature").attr("value", data.url);
                    $("#signature-image").attr("src", data.url);
                    if ( target ) {
                        $( "#" + target ).attr("value", data.url);
                        $( "#" + target + '-image').attr("src", data.url);
                        target = null;
                    }
                }
            },
            onReset: function () {
                console.log("onReset");
            },
            onError: function (errormessage) {
                console.log("onError:" + errormessage);
            }
        };

        cropper = new Croppic(elementId, croppicOptions);
        croppic_init_callback();
    }
}

function loadKendoUploadFiles() {
    $("#requestDocuments").kendoUpload({
        validation: {
                    allowedExtensions: [".jpeg",".docx",".doc",".pdf", ".jpg", ".png",".xls",".xlsx"],
                    maxFileSize: 62914560 //60 Mb
                }
    });
    $("#patientDocuments").kendoUpload({
        validation: {
                    allowedExtensions: [".jpeg",".docx",".doc",".pdf", ".jpg", ".png",".xls",".xlsx"],
                    maxFileSize: 62914560 //60 Mb
                }
    });
    $("#migrateOrders").kendoUpload({
        validation: {
            allowedExtensions: [".csv"],
            maxFileSize: 62914560 //60 Mb
        }
    });
}
function modalScanFile() {

    $("#scan-file").on("click", function (e) {
        $("#scan-file-modal").modal("show");
    });

}

function receptionSearchPatient() {
    var returnedData = "";

    $("#patient-search-modal").on("hidden.bs.modal", function (e) {
        $("#search-modal-content").html("");
    });

    $("#patient-search-modal").on("shown.bs.modal", function (e) {
        $("#search-modal-content").html(returnedData);
    });

    $("#search-patient").on("click", function (e) {
        e.preventDefault();

        var data = [
            {name: 'patient_ID_search', value: $("#patient_ID_search").val()},
            {name: 'email_search', value: $("#email_search").val()},
            {name: 'first_name_search', value: $("#first_name_search").val()},
            {name: 'last_name_search', value: $("#last_name_search").val()},
            {name: 'appointment_id', value: $("input[name*='appointment_id']").val()}
        ];

        if (
            data[0].value == "" &&
            data[1].value == "" &&
            data[2].value == "" &&
            data[3].value == ""
        ) {
            kendo.alert(error5002());
            return false;
        }

        $.ajax({
            url: "/patients/search",
            type: "POST",
            data: data,
            success: function (r) {
                returnedData = r;
                $("#patient-search-modal").modal("show");
            },
            error: function (xhr, responseText, response) {
                kendo.alert(responseText);
            }
        });
    });
}

function searchPatientOnPressingEnterKey() {
    $(
        "#patient_ID_search, #email_search, #search-patient-form #first_name, #search-pat" +
        "ient-form #last_name"
    ).keyup(function (e) {
        if (e.which == 13) {
            $("#search-patient").trigger("click");
            return false;
        }
    });
}

function searchRepresantative() {
    //cédula: it could could be 1 up to 8 digits or
    //we can add a dash and a number to represent an individual without cedula, in that
    //case, we use the father or mother cedula with an aditional digit
    $("#patient_ID").kendoMaskedTextBox({
        //mask: "00000000-00",
        unmaskOnPost: false,
        clearPromptChar: true
    });

    $("#patient_ID").blur( function() {
        //get current value and remove _ that are included by the mask
        ci = $(this).val();
        ci = ci.replace(/_/g, "");
        ci = ci.replace(/\s/g, "");
        //find out if it has a dash (that means, someone without CI, they are using the representative CI)
        //then we try to find the representative from the patients that we have
        arrCi = ci.split("-");

        if ( arrCi[1] != '' && arrCi[1] != undefined ) {
            $(this).val(arrCi[0]+'-'+arrCi[1]);

            $.ajax({
                url: '/patients/info',
                type: 'POST',
                data: {
                    patient_identification_id: arrCi[0],
                },
                async:false
            })
            .done(function(response) {
                if ( response.id ) {
                    $('#responsable_id').val(response.patient_ID);
                    $('#responsable_name').val(response.first_name + ' ' + response.last_name);
                }
            })
            .fail(function(response){});
        } else {
            $(this).val(arrCi[0]);
        }
    }).focus( function() {
        $('#responsable_id').val('');
        $('#responsable_name').val('');
    }).blur();
}

function searchAppointment() {




    var returnedData = "";
    $("#appointment-search-modal").on("hidden.bs.modal", function (e) {
        $("#search-modal-content").html("");
    });

    $("#appointment-search-modal").on("shown.bs.modal", function (e) {
        $("#search-modal-content").html(returnedData);
    });


    $("#search-appointment").click(function (e) {
        e.preventDefault();
        if ($("#search_criteria").val()) {
            var searchCriteria = {search_criteria: $("#search_criteria").val()};

            $.ajax({
                url: "appointments/search",
                type: "POST",
                data: searchCriteria,
                async: false,
                success: function (response) {
                    returnedData=response;
                    $("#appointment-search-modal").modal("show");
                }
            });
        } else {
            kendo.alert(ProvideSearchCriteria());
        }
    });
}

function removeFilefromInput() {
    $(".k-delete-file").click(function () {
        var id = this.id;
        var array = id.split("-");

        var fileToDelete = $(
            "#k-file-" + array[3] + " .k-file-name:first-child"
        ).attr("filename");

        $("#k-file-" + array[3]).remove();

        var size = $(".documentToDelete").size();
        var deleteLocation = $(this).attr("deleteLocation");
        var html = '<input type="hidden" class="documentToDelete" name="' +
            deleteLocation +
            "[" +
            size +
            ']" value="' +
            fileToDelete +
            '"/>';

        $("#" + deleteLocation).append(html);
    });
}

//El metodo kendoMaskedTextBox( unmasOnPost() ) causa conflicto con el validate de jQuery
//por eso se escribio esta funcion, para evitar hacer doble click
function initMaskedInputs() {
    $("#telephone_number, #telephone_number_2, #cellphone_number, #cellphone_number_2, " +
      "#patient_cellphone_number, #patient_telephone_number"
    ).each( function(){

        $( this ).clone().appendTo( this ).prop('type', 'hidden');

        var id = $(this).attr('id');

        $(this).attr('id', id+'_masked').attr('name', id+'_masked');

        $( this ).kendoMaskedTextBox({
            mask: "(9999) 000-0000"
        });

    });

    unmaskPhones();
    //Formato de la horas de inicio y fin de las salas.
    //Rooms start and end hours format.
    $("#start_hour, #end_hour").kendoMaskedTextBox({
        mask: "~9:<9 >^",
        rules: {
        "~": /[0-1]/,
        "<":/[0-5]/,
        ">":/[AP]/,
        "^":/[M]/
        }
    });
    //cedula representante, 1 up to 8 digits.
    $("#responsable_id").kendoMaskedTextBox({
        mask: "00000000",
        unmaskOnPost: true
    });
}

function unmaskPhones() {
    $("body").on('change', "input[name$='_masked']" ,
        function(){

            var maskedtextbox = $(this).data("kendoMaskedTextBox");

            var raw = maskedtextbox.raw();

            var old_id = $(this).attr('id');

            var new_id = old_id.substr(0,old_id.length - 7);

            $('#'+new_id).val(raw);

        });

}

/*:::::::::::::: FUNCTIONS OUT OF READY DOCUMENT ::::::::::::::::*/
function onDataBound() {
    openColorBox();
    setGridStorageFields(this);
}

function onDataBoundRemote() {
    $('[data-toggle="tooltip"]').tooltip();
    changeOrderStatusOrBlock();
    changeOrderStatusOrBlockRadiologist();
    setGridStorageFields(this);
    rewriteTechnicianOrder();
    revertRadiologistOrder();
    approvePreadmissionOrder();
    rejectAppointment();
}

/****  Break Points Creation  ****/
if ($.fn.setBreakpoints) {
    $(
        window
    ).setBreakpoints({distinct: true, breakpoints: [320, 480, 768, 1200]});
}

// ******************************** RETINA READY
// *******************************//
Modernizr.addTest("retina", function () {
    return !!navigator.userAgent.match(/Macintosh|Mac|iPhone|iPad/i) &&
        window.devicePixelRatio == 2;
});

Modernizr.load([
    {test: Modernizr.retina, yep: "/plugins/retina/dist/retina.min.js"}
]);

//******************************** MAIN SIDEBAR ******************************//
var $html = $("html");
var $wrapper = $("#wrapper");
var $sidebar = $("#sidebar");
var $sidebar_toggle = $(".sidebar-toggle");
var $sidebar_submenu = $(".submenu");

function manageSidebar() {
    /* We change sidebar type on resize event */
    $(window).bind("enterBreakpoint1200", function () {
        $html.removeClass().addClass("sidebar-large");
        $(".sidebar-nav li.current").addClass("active");
        $(".sidebar-nav li.current .submenu").addClass("in").height("auto");
        $sidebar_toggle.attr("id", "menu-medium");
        $sidebar.removeClass("collapse");
        sidebarHeight();
        createSideScroll();
    });

    $(window).bind("enterBreakpoint768", function () {
        $html
            .removeClass("sidebar-hidden")
            .removeClass("sidebar-large")
            .removeClass("sidebar-thin")
            .addClass("sidebar-medium");
        $(".sidebar-nav li.current").removeClass("active");
        $(".sidebar-nav li.current .submenu").removeClass("in");
        $sidebar_toggle.attr("id", "menu-thin");
        sidebarHeight();
        $sidebar.removeClass("collapse");
        $("#menu-right").trigger("close");
        destroySideScroll();
    });

    $(window).bind("enterBreakpoint480", function () {
        $html
            .removeClass("sidebar-medium")
            .removeClass("sidebar-large")
            .removeClass("sidebar-hidden")
            .addClass("sidebar-thin");
        $(".sidebar-nav li.current").removeClass("active");
        $(".sidebar-nav li.current .submenu").removeClass("in");
        $sidebar.removeClass("collapse");
        sidebarHeight();
        destroySideScroll();
    });

    $(window).bind("enterBreakpoint320", function () {
        $html
            .removeClass("sidebar-medium")
            .removeClass("sidebar-large")
            .removeClass("sidebar-thin")
            .addClass("sidebar-hidden");
        sidebarHeight();
        destroySideScroll();
    });

    /* We change sidebar type on click event */
    $(document).on("click", "#menu-large", function () {
        $html
            .removeClass("sidebar-medium")
            .removeClass("sidebar-hidden")
            .removeClass("sidebar-thin")
            .addClass("sidebar-large");
        $sidebar_toggle.attr("id", "menu-medium");
        $(".sidebar-nav li.current").addClass("active");
        $(".sidebar-nav li.current .submenu").addClass("in").height("auto");
        sidebarHeight();
        createSideScroll();
    });

    $(document).on("click", "#menu-medium", function () {
        if ($("#sidebar").css("left") == "-350px") {
            $("#sidebar").animate({left: "0px"});
            $("#wrapper").animate({"padding-left": "200px"});
        } else {
            $("#sidebar").animate({left: "-350px"});
            $("#wrapper").animate({"padding-left": "0"});
        }
    });
}

/****  Improve Dropdown effect  ****/
if ($('[data-hover="dropdown"]').length && $.fn.popover) {
    $('[data-hover="dropdown"]').popover();
}

/****  Custom Range Slider  ****/
if ($(".range-slider").length && $.fn.rangeSlider) {
    $(".range-slider").each(function () {
        $(
            this
        ).rangeSlider({
            delayOut: $(this).data("slider-delay-out") ? $(this).data("slider-delay-out") : "0",
            valueLabels: $(this).data("slider-value-label") ? $(this).data("slider-value-label") : "show",
            step: $(this).data("slider-step") ? $(this).data("slider-step") : 1
        });
    });
}

/****  Back to top on menu click when screen size < 480px (to see menu content) ****/
$(".navbar-toggle").click(function () {
    $("html, body").animate({scrollTop: 0}, 1000);
});

function rotateArrow(elementId) {
    var arrows = $(".arrow");

    $.each(arrows, function (i, obj) {
        if (obj.id != elementId) {
            $(
                "#" + obj.id
            ).css({
                "-webkit-transform": "rotate(0deg)",
                "-moz-transform": "rotate(0deg)",
                "-ms-transform": "rotate(0deg)",
                transform: "rotate(0deg)"
            });
        }
    });

    if (
        $("#" + elementId).css("transform") == "none" ||
        $("#" + elementId).css("transform") == "matrix(1, 0, 0, 1, 0, 0)"
    )
        deg = "90deg";
    else
        deg = "0deg";

    $("#" + elementId).css({
        "-webkit-transform": "rotate(" + deg + ")",
        "-moz-transform": "rotate(" + deg + ")",
        "-ms-transform": "rotate(" + deg + ")",
        transform: "rotate(" + deg + ")"
    });
}

/* Toggle submenu open */
function toggleSidebarMenu() {
    var $this = $(".sidebar-nav");
    var deg;

    $this.find("li.active").has("ul").children("ul").addClass("collapse in");
    $this.find("li").not(".active").has("ul").children("ul").addClass("collapse");
    $this.find("li").has("ul").children("a").on("click", function (e) {
        e.preventDefault();
        $(".arrow-white").css("visibility", "hidden");
        $("li.current").removeClass("current");
        $(this).parent("li").addClass("current");

        var elemId = $(this).children("span.arrow").attr("id");
        $(this).find(".arrow-white").css("visibility", "visible");

        $(this)
            .parent("li")
            .toggleClass("active")
            .children("ul")
            .collapse("toggle");
        $(this)
            .parent("li")
            .siblings()
            .removeClass("active")
            .children("ul.in")
            .collapse("hide");

        rotateArrow(elemId);
    });
}

/* Adjust sidebar height */
function sidebarHeight() {
    var sidebar_height;
    var mainMenuHeight = parseInt($("#main-menu").height());
    var windowHeight = parseInt($(window).height());
    var mainContentHeight = parseInt($("#main-content").height());
    if (windowHeight > mainMenuHeight && windowHeight > mainContentHeight)
        sidebar_height = windowHeight;
    if (mainMenuHeight > windowHeight && mainMenuHeight > mainContentHeight)
        sidebar_height = mainMenuHeight;
    if (mainContentHeight > mainMenuHeight && mainContentHeight > windowHeight)
        sidebar_height = mainContentHeight;
    if ($html.hasClass("sidebar-large") || $html.hasClass("sidebar-hidden")) {
        $sidebar.height("");
    } else {
        $sidebar.height(sidebar_height);
    }
}

function loadTimePickers() {
    $("#start_hour").kendoTimePicker();
    $("#end_hour").kendoTimePicker();
}

function addFormProcedure() {
    $("#addProcedureRoom").click(function () {
        var value = $("#procedure_id").val();
        var duration = $("#default_duration").val();

        if (duration < 1) {
            duration = 1;
        }

        var randomID = randomString(12);

        if (value) {
            //Check if the procedure was already added
            if (!$("#procedures .panel").hasClass("procedure_" + value)) {
                $.get(
                    "/procedures/form/" + randomID + "/" + value + "/" + duration,
                    function (data) {
                        $("#procedures").hide().append(data).fadeIn(400);
                    }
                );
            }
        } else {
            errorAddProcedure();
        }
    });
    $("#removeProcedureRooms").click(function () {
        $('.procedure').closest('.col-xs-12').remove();
    });
}

function addAllFormProcedure() {
    $("#addAllProcedureRoom").click(function () {
        $("select#procedure_id option").each(function () {
            if ( $(this).attr('disabled') == undefined ) {
                var value = $(this).val();

                var duration = $("#default_duration").val();

                if (duration < 1) {
                    duration = 1;
                }

                var randomID = randomString(12);

                if (value) {
                    //Check if the procedure was already added
                    if (!$("#procedures .panel").hasClass("procedure_" + value)) {
                        $.get(
                            "/procedures/form/" + randomID + "/" + value + "/" + duration,
                            function (data) {
                                $("#procedures").hide().append(data).fadeIn(400);
                            }
                        );
                    }
                }
            }
        });
    });
}

function deleteLastProcedure() {
    $("#removeProcedureRoom").click(function () {
        $(".procedure:last-child").fadeOut(function () {
            $(this).remove();

            if ($(".procedure").size() == 0) {
                $("#removeProcedureRoom").hide();
            }
        });
    });
}

function generateBlocks() {
    $("#block_size").change(function () {
        $("#blocks_locked").remove();
        $(".k-multiselect").remove();

        var start_hour = moment($("#start_hour").val(), "h:mm A");
        var end_hour = moment($("#end_hour").val(), "h:mm A");
        var block_size = $("#block_size").val();

        var block_number = 0;
        var html = '<select class="form-control" name="blocks_locked[]" id="blocks_locked" multiple>';
        while (start_hour.format("h:mm A") != end_hour.format("h:mm A")) {
            html = html +
                '<option value="' +
                block_number +
                '">' +
                start_hour.format("h:mm A") +
                " - " +
                start_hour.add(block_size, "m").format("h:mm A") +
                "</option>";

            block_number++;
        }

        html = html + "</select>";

        $("#lock_blocks").append(html);

        multiSelectsInit();
    });
}

function getHours(block_size, start_hour, end_hour, block_number) {
    start_hour = moment(start_hour, "h:mm A");
    end_hour = moment(end_hour, "h:mm A");

    var block_index = 0;

    while (start_hour.format("h:mm A") != end_hour.format("h:mm A")) {
        if (block_index == block_number) {
            return start_hour.format("h:mm A") +
                "-" +
                start_hour.add(block_size, "m").format("h:mm A");
        } else {
            block_index++;
            start_hour.add(block_size, "m").format("h:mm A");
        }
    }
}

function rejectAppointment() {
    $(".reject-appointment").on("click", function (e) {
        e.preventDefault();

        var url = $(this).prop("href");
        var status_id = $(this).attr("status-attr");
        var status_span = $(status_id);
        var loader = status_span.next();

        $("#appointment-reject-modal").modal("show");

        $("#appointment-reject-modal").on("hide.bs.modal", function (e) {
            var rejectionReason = $("#rejection-reason").val();
            var data = {cancelation_reason: rejectionReason};

            $.ajax({
                url: url,
                type: "POST",
                dataType: "JSON",
                data: data,
                beforeSend: function () {
                    status_span.addClass("hide");
                    loader.addClass("showing");
                },
                success: function (r) {
                    if (r.code == "200") {
                        $('#grid').data("kendoGrid").dataSource.read();

                        $("#rejection-reason").empty();
                    }
                },
                error: function (data, textStatus, xhr) {
                    kendo.alert( errorUnknown() );
                    console.log("Error: " + textStatus);
                }
            });
        });
    });

    $("#save-appointment-reject-status").click(function (e) {
        $("#appointment-reject-modal").modal("hide");
    });
}

function changeOrderStatusOrBlock() {
    $('.change-order-status').click(function (e) {
        e.preventDefault();
        var url = $(this).prop('href');

        var action_button = $(this);
        var status_attr = $(this).attr('status-attr');
        var icon = $(this).children('i');
        var grid = $('#grid');
        var loader = $(this)
            .parent()
            .children('.loader');

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'JSON',
            beforeSend: function () {
                action_button.addClass('hide');
                loader.addClass('showing');
            },
            success: function (r) {

                if (r.code == "200") {

                    if (status_attr == 'lock') {

                        grid
                            .data('kendoGrid')
                            .dataSource
                            .read();

                    } else if (status_attr == 'suspended') {
                        grid
                            .data('kendoGrid')
                            .dataSource
                            .read();
                    }

                } else if (r.code == "5000") {
                    kendo.alert(r.message + ': ' + r.serviceRequest.requested_procedures[0].technician_user_name);
                    loader.removeClass('showing');
                    action_button.removeClass('hide');

                } else if (r.code == "403") {

                    kendo.alert(r.message);
                    loader.removeClass('showing');
                    action_button.removeClass('hide');

                }
            },
            error: function (data, textStatus, xhr) {
                console.log(data);
                console.log('Error: ' + textStatus);
            }
        });
    });
}

//Functions to radiologist
function changeOrderStatusOrBlockRadiologist() {
    $(".change-order-status-radiologist, .addToListRadiologist")
        .off("click")
        .click(function () {
            var action_button = $(this);
            var loader = $(this).parent().children(".loader");
            var grid = $("#grid");
            var grid1 = $("#grid1");
            var grid2 = $("#grid2");
            var grid5 = $("#grid5");

            $.ajax({
                url: "/radiologist/lock/" + $(this).attr("attr-id"),
                type: "POST",
                dataType: "JSON",
                beforeSend: function () {
                    action_button.addClass("hide");
                    loader.addClass("showing");
                },
                success: function (response) {
                    if (response.code == "200") {
                        grid.data("kendoGrid").dataSource.read();
                        grid1.data("kendoGrid").dataSource.read();
                        grid2.data("kendoGrid").dataSource.read();
                        grid5.data("kendoGrid").dataSource.read();
                        /*if(action_button.children('i').hasClass('fa-unlock'))
                         {
                         action_button.children('i').removeClass('fa-unlock').addClass('fa-lock');
                         action_button.tooltip('hide').attr('title', AlertStatusOrder(1)).tooltip('fixTitle').tooltip('show');
                         }
                         else
                         {
                         action_button.children('i').removeClass('fa-lock').addClass('fa-unlock');
                         action_button.tooltip('hide').attr('title', AlertStatusOrder(0)).tooltip('fixTitle').tooltip('show');
                         }*/
                        loader.removeClass("showing");
                        action_button.removeClass("hide");
                    }

                    kendo.alert(AlertStatusOrder(response.newValue.block_radiologist_status));
                },
                error: function () {
                    alert(
                        "Ha ocurrido un error. Por favor intentelo de nuevo. Si el problema persiste cons" +
                        "ulte a un técnico de soporte."
                    );
                }
            });
        });
}

function clearKendoGridFilters() {

    $("#clear-filters-1, #clear-filters-2").on("click", function (event) {

        var datasource = $("#grid").data("kendoGrid").dataSource;
        //Clear filters:
        datasource.filter([]);

        // Radiologist
        var grids = $(".k-grid");
        if(grids.length > 1) {
            var tab  = $('li.active a[data-toggle="tab"]').attr('href'),
                grid = tab + ' .k-grid';
            // Clear Filters
            var datasource = $(grid).data("kendoGrid").dataSource;
            if(tab != '#addendum'){
                datasource.filter([]);
            } else {
                datasource.read();
            }
        }

    });
}


function kendoGridAutoRefresh(grid , time ) {

    if(grid==null){
        grid="#grid";
    }
    if(!time){
        time=30;
    }
    setInterval(function() {
        $(grid).data("kendoGrid").dataSource.read();
    }, time * 1000); //CADA 30 SEGUNDOS

}

function randomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

function printButtons() {
    $("button#print-label").click(function (e) {
        window.print();
    });
}

function approvePreadmissionOrder() {
    $("a#preadmission-approve-order").on("click", function (e) {
        e.preventDefault();

        var action_button = $(this);

        var url = $(this).attr("href");
        var loader = $(this).parent().children(".loader");
        var grid = $("#grid");

        $.ajax({
            url: url,
            type: "POST",
            dataType: "JSON",
            beforeSend: function () {
                action_button.addClass("hide");
                loader.addClass("showing");
            },
            success: function (r) {
                if (r.code == "200") {
                    grid.data("kendoGrid").dataSource.read();

                    loader.removeClass("showing");
                    action_button.removeClass("hide");
                    kendo.alert(r.message);
                }
            },
            error: function (r) {
                if (r.status == 401) {
                    loader.removeClass("showing");
                    action_button.removeClass("hide");
                    kendo.alert(NotAuthorized());
                }
            }
        });
    });
}

function saveDraft() {
    $("a#btn-save-draft").on("click", function (e) {
        e.preventDefault();

        var action_button = $(this);

        var url = $(this).attr("href");
        var currentIcon = $("i", this).attr("class");
        var loaderIcon = "fa fa-spinner fa-pulse";

        $.ajax({
            url: url,
            type: "POST",
            dataType: "JSON",
            data: {"text": $("#template").val()},
            beforeSend: function () {
                $("i", action_button).attr("class", loaderIcon);
            },
            success: function (r) {
                if (r.code == "200") {
                    $("i", action_button).attr("class", currentIcon);
                    kendo.alert(r.message);
                }
            },
            error: function (r) {
                $("i", action_button).attr("class", currentIcon);

                if (r.status == 401) {
                    kendo.alert(NotAuthorized());
                } else {
                    kendo.alert(r.statusText);
                }
            }
        });
    });
}

//:::::::::::::::::::::::::::: SEARCH SECTION ::::::::::::::::::::::::::::::://
/**
 * @fecha 19-12-2016
 * @programador Pascual Madrid
 * @objetivo Remueve los índices de filtros que tengan el mismo nombre suministrado.
 */
function removeRepeatedFilters(filters, field_name) {
    for (var i = 0; i < filters.length; i++) {
        if (filters[i].field == field_name) {
            filters.splice(i, 1);
        }
    }

    return filters;
}

/**
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Declara todos los eventos relacionados a la vista de Búsqueda para el manejo de filtros.
 */
function changeSearchColumns() {
    var grid = $("#grid").getKendoGrid();

    if (grid != undefined) {
        //---NEW BLOCK---//
        var options = $.extend({}, grid.dataSource.options);

        options.filter = {};

        var dataSource = new kendo.data.DataSource(options);

        grid.setDataSource(dataSource);

        grid.dataSource.read();
        //---NEW BLOCK---//
        var ds = grid.dataSource;
        var filters = ds.filter();

        if (filters == null || filters == undefined) {
            filters = [];
        } else {
            filters = filters.filters;
        }

        var select = $("#search-filter2");
        select.data("pre", select.val());

        var select2 = $("#search-filter1");
        select2.data("pre", select2.val());

        $("#search-filter1, #search-filter2").on("change", function (e) {
            var selected = parseInt($(this).val());
            var grid = $("#grid").getKendoGrid();
            var previous = parseInt($(this).data("pre"));

            grid.hideColumn(previous);
            grid.showColumn(selected);

            $(this).data("pre", selected);
        });

        $("#search-order1, #search-order2").off().on("click", function (e) {
            e.preventDefault();

            filters = [];

            var value = $("#birad").val();
            value == 999
                ? value = null
                : filters.push(createAndPushFilter("biradID", value, "eq"));

            value = $("#biopsy-results").val();
            value == 999
                ? value = null
                : filters.push(createAndPushFilter("biopsyResult", value, "eq"));

            value = $("#reportText").val();
            value == ""
                ? value = null
                : filters.push(createAndPushFilter("reportText", value, "contains"));

            value = $("#category").val();
            value == 999
                ? value = null
                : filters.push(createAndPushFilter("categoryID", value, "eq"));

            value = $("#sub-category").val();
            value == 999
                ? value = null
                : filters.push(createAndPushFilter("subCategoryID", value, "eq"));

            value = $("#teaching-file-text").val();
            value == ""
                ? value = null
                : filters.push(
                createAndPushFilter("teachingFileText", value, "contains")
            );

            ds.filter(filters);
        });

        $("#clear-search-1, #clear-search-2").on("click", function (event) {
            var grid = $("#grid").getKendoGrid();
            var options = $.extend({}, grid.dataSource.options);

            filters = [];
            options.sort = {};
            options.filter = {};
            options.page = 1;
            $("#birad").val("");
            $("#biopsy-results").val("");
            $("#reportText").val("");
            $("#sub-category").val("");
            $("#category").val("");
            $("#teaching-file-text").val("");

            var dataSource = new kendo.data.DataSource(options);

            grid.setDataSource(dataSource);

            grid.dataSource.read();
        });
    }

    function createAndPushFilter(field, value, operator) {
        return filter = {field: field, operator: operator, value: value};
    }
}

// :::::::::::::::::::::::::::: GRID EVENT FUNCTIONS
// ::::::::::::::::::::::::::::::://
/**
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @param grid KendoGrid
 * @objetivo Inicializa los campos del objeto sessionStorage usados para almacenar los
 * respectivos datos para que el grid "recuerde" en que página se encontraba al salir
 * o recargar nuevamente la url.
 */
function setGridStorageFields(grid) {
    var dataSource = grid.dataSource;

    var page = dataSource.page();

    setGridPagingStorage(page);

    setGridSortingStorage(JSON.stringify(dataSource.sort()));
    setGridFilteringStorage(JSON.stringify(dataSource.filter()));
    setGridPagingStorage(JSON.stringify(dataSource.page()));
    // console.log(JSON.parse(getGridSortingStorage()),
    // JSON.parse(getGridFilteringStorage()), JSON.parse(getGridPagingStorage()));
}

// :::::::::::::::::::::::::::: STORAGE FUNCTIONS
// ::::::::::::::::::::::::::::::::::// :::::::::::::::::: SETTERS
// ::::::::::::::::::://
/**
 * @función setCurrentViewStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @param view string
 * @objetivo Guarda en sessionStorage.currentView la sección actual en la que se encuentra
 * el usuario.
 * @return string
 */
function setCurrentViewStorage(view) {
    return sessionStorage.currentView = view;
}

/**
 * @función setOldViewStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @param view string
 * @objetivo Guarda en sessionStorage.oldView el valor de la sección anterior en la que se
 * encontraba el usuario (sessionStorage.oldView).
 * @return string
 */
function setOldViewStorage() {
    return sessionStorage.oldView = sessionStorage.currentView;
}

/**
 * @función setGridPagingStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @param page int
 * @objetivo Guarda en sessionStorage.gridPaging el número de página del grid en donde se
 * encuentra el usuario.
 * @return string
 */
function setGridPagingStorage(page) {
    return sessionStorage.gridPaging = page;
}

/**
 * @función setGridSortingStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @param jsonSortingOptions jsonString
 * @objetivo Guarda en sessionStorage.gridSorting las opciones de ordenamiento usadass para
 * ordenar el grid en donde se encuentra el usuario.
 * @return string
 */
function setGridSortingStorage(jsonSortingOptions) {
    return sessionStorage.gridSorting = jsonSortingOptions;
}

/**
 * @función setGridFilteringStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @param jsonSortingOptions jsonString
 * @objetivo Guarda en sessionStorage.gridFiltering los filtros usados para ordenar el grid en donde se
 * encuentra el usuario.
 * @return string
 */
function setGridFilteringStorage(jsonFilteringOptions) {
    return sessionStorage.gridFiltering = jsonFilteringOptions;
}

//:::::::::::::::::: GETTERS ::::::::::::::::::://
/**
 * @función getCurrentViewStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Retorna la el parámetro guardado en sessionStorage.currentview.
 * @return string
 */
function getCurrentViewStorage() {
    return sessionStorage.currentView;
}

/**
 * @función getOldViewStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Retorna la el parámetro guardado en sessionStorage.oldView.
 * @return string
 */
function getOldViewStorage() {
    return sessionStorage.oldView;
}

/**
 * @función getGridPagingStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Retorna el parámetro guardado en sessionStorage.gridPaging. Si el valor es
 * "undefined" retorna 1 sino convierte el valor guardado a un entero y lo retorna.
 * @return int
 */
function getGridPagingStorage() {
    return sessionStorage.gridPaging == "undefined"
        ? 1
        : parseInt(sessionStorage.gridPaging);
}

/**
 * @función getGridSortingStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Retorna el parámetro guardado en sessionStorage.gridSorting. Si el valor es
 * "undefined" retorna 1 sino convierte el valor guardado a un entero y lo retorna.
 * @return int
 */
function getGridSortingStorage() {
    return sessionStorage.gridSorting == "undefined"
        ? {}
        : JSON.parse(sessionStorage.gridSorting)[0];
}

/**
 * @función getGridFilteringStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Retorna el parámetro guardado en sessionStorage.gridFiltering. Si el valor es
 * "undefined" retorna 1 sino convierte el valor guardado a un entero y lo retorna.
 * @return int
 */
function getGridFilteringStorage() {
    return sessionStorage.gridFiltering == "undefined"
        ? {}
        : JSON.parse(sessionStorage.gridFiltering);
}

//:::::::::::::::::: CLEANERS ::::::::::::::::::://
/**
 * @función cleanGridStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Limpia y setea el valor por defecto para los campos usados del sessionStorage.
 */
function cleanGridStorage() {
    sessionStorage.gridPaging = "undefined";
    sessionStorage.gridFiltering = "undefined";
    sessionStorage.gridSorting = "undefined";
    sessionStorage.currentView = "undefined";
    sessionStorage.oldView = "undefined";
}

/**
 * @función initGridStorage
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Inicializa los campos del sessionStorage para el paging, filtering y sorting del grid.
 */
function initGridStorage() {
    sessionStorage.gridPaging === undefined
        ? sessionStorage.gridPaging = "undefined"
        : null;
    sessionStorage.gridFiltering === undefined
        ? sessionStorage.gridFiltering = "undefined"
        : null;
    sessionStorage.gridSorting === undefined
        ? sessionStorage.gridSorting = "undefined"
        : null;
}

//:::::::::::::::::: EXECUTION ::::::::::::::::::://
/**
 * @función checkCurrentGridView
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Chequea la vista actual en la que se encuentra el usuario, guarda la anterior y retorna un objeto
 * que contiene la instancia del grid y la vista actual.
 * @return object
 */
function checkCurrentGridView() {
    var grid = $("#grid");

    var object = {};

    if (grid.length) {
        var view = grid.attr("view-attr");

        setOldViewStorage();

        setCurrentViewStorage(view);

        object = {grid: grid.getKendoGrid(), view: view};
    }

    return object;
}

/**
 * @función checkEqualGridViews
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @param view string
 * @objetivo Chequea si el usuario se encuentra en la misma vista o es la primera vez que
 * inicia el funcionamiento de memoria del grid.
 * @return string
 */
function checkEqualGridViews(view) {
    if (sessionStorage.oldView == view || sessionStorage.oldView == "undefined") {
        return view;
    }

    return undefined;
}

/**
 * @función restoreLastGridSettings
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @param grid object
 * @objetivo Restaura los filtros, orden y paginación del último grid para que el usuario
 * lo encuentre en el mismo lugar que la encontró.
 */
function restoreLastGridSettings(grid) {
    var options = $.extend({}, grid.dataSource.options);

    options.sort = getGridSortingStorage();
    options.filter = getGridFilteringStorage();
    options.page = getGridPagingStorage();

    if (
        !$.isEmptyObject(options.sort) || !$.isEmptyObject(options.filter) ||
        options.page > 1
    ) {
        var dataSource = new kendo.data.DataSource(options);

        grid.setDataSource(dataSource);

        grid.dataSource.read();
    }
}

/**
 * @función GridMemoryON
 * @fecha 16-12-2016
 * @programador Juan Bigorra
 * @objetivo Activa el funcionamiento de memoria del grid.
 */
function GridMemoryON() {
    initGridStorage();

    var gridView = checkCurrentGridView();

    if (!$.isEmptyObject(gridView)) {
        if (gridView.view != "undefined") {
            if (checkEqualGridViews(gridView.view)) {
                restoreLastGridSettings(gridView.grid);
            }
            /*else {
             cleanGridStorage();
             }*/
        }
    }
}

/**
 * @función GridRefreshTab
 * @fecha 04-12-2017
 * @programador Alton Bell Smythe
 * @objetivo Refresca el Grid con el cambio de tab // Radiologist
 */
function GridRefreshTab() {

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (element) {
        var tab   = $(this).attr('href'),
            grid  = tab + ' .k-grid';
        $(grid).data("kendoGrid").refresh();
    });

}


// function insertTemplate() {
//     $("#insert-template").click(function () {
//         var template_id = $("select#template_id").val();
//
//         if (template_id) {
//             var templateText = $("#template_" + template_id).val();
//
//             if (confirm(ConfirmTemplateInsertion())) {
//                 $("#template").data("kendoEditor").value(templateText);
//             }
//         } else {
//             kendo.alert(SelectValidTemplate());
//         }
//     });
// }

function insertTemplate() {

    $('#template_id').on('change', function () {

        var template_id = $('select#template_id').val();

        if (template_id) {

            if (template_id == 'create-temprate') {
                var hostname = window.location.hostname;
                window.location.href = '/templates/add';
            } else {

                var templateText = $('#template_' + template_id).val();

                if (confirm(ConfirmTemplateInsertion())) {
                    $('#template')
                        .data('kendoEditor')
                        .value(templateText);
                }
            }

        } else {

            kendo.alert(SelectValidTemplate());
        }

    });
}

function checkToApproveOrderRadiologist() {
    $(".submit-approve").click(function () {
        if ( ImpersonateUser.canSubmit( $(this) ) ) {
            ImpersonateUser.injectUser( $("#RadiologistEditForm") );
            $("#approve").val(1);
            $("#RadiologistEditForm").submit();
            $("#approve").val(0);
        }
    });
}

function checkToRejectOrderRadiologist() {
    $(".submit-reject-transcription").click(function () {

        $("#rejectOrder").modal("show");
        $("#reject-modal-form").click(function(){

            var reject_reason = $("#rejectOrder").find('input[name=reject-input-reason]').val();
            if (reject_reason != "") {
                $("#reject").val(1);
                $("#reject_reason").val(reject_reason);
                $("#RadiologistApproveForm").submit();
                $("#reject").val(0);
                $("#reject_reason").val("");
            } else {
                kendo.alert(WrongReasonToCancel());
            }
        });
        $("#rejectOrder").on("hidden.bs.modal");
    });
}

function paginationPatientHistory() {
    $(".pagination-link").click(function () {
        $(".panel-history").hide();
        var panel_id = "panel_history_" + $(this).attr("panel-id");
        $("#" + panel_id).show();

        $(".pagination-link").removeClass("active");
        $(".pagination-link").removeClass("disabled");

        $(this).addClass("active");
        $(this).addClass("disabled");
    });
}

function rewriteTechnicianOrder() {
    $(".rewrite-order").off("click").click(function () {
        var action_button = $(this);
        var loader = $(this).parent().children(".loader");
        var grid = $("#grid");

        $.ajax({
            url: "technician/rewrite/" + $(this).attr("attr-id"),
            type: "POST",
            dataType: "JSON",
            beforeSend: function () {
                action_button.addClass("hide");
                loader.addClass("showing");
            },
            success: function (response) {
                if (response.code == "200") {
                    grid.data("kendoGrid").dataSource.read();

                    loader.removeClass("showing");
                    action_button.removeClass("hide");
                    kendo.alert("Orden reescrita");
                }
            },
            error: function (response) {
                if (response.status == 401) {
                    loader.removeClass("showing");
                    action_button.removeClass("hide");
                    kendo.alert(NotAuthorized());
                }
            }
        });
    });

    $("#requestedProcedure\\[equipment_id\\]").on('change', function() {
        requestedProcedureId = $("#requested_procedure_id").val();
        equipmentId = $(this).val();

        $.ajax({
            url: "/technician/rewrite/" + requestedProcedureId + "/" + equipmentId,
            type: "POST",
            dataType: "JSON",
            success: function (response) {
                if (response.code == "200") {

                    var notification = $("#notification").kendoNotification({
                        position: {
                            pinned: true,
                            bottom: 30,
                            right: 30
                        },
                        autoHideAfter: 2000,
                        stacking: "down",
                        templates: [{
                            type: "success",
                            template: $("#equipmentTemplate").html()
                        }]

                    }).data("kendoNotification");
                    notification.show({
                        title: WorklistTittle(),
                        message: RewriteWorklist()
                    }, "success");

                }
            },
            error: function (response) {
                if (response.status == 401) {
                    kendo.alert(NotAuthorized());
                }
            }
        });
    });
}

function revertRadiologistOrder() {
    $(".revert-order").off("click").click(function () {
        var action_button = $(this);
        var loader = $(this).parent().children(".loader");
        var grid = $("#grid");
        var grid1 = $("#grid1");
        var grid4 = $("#grid4");

        $.ajax({
            url: "/radiologist/revert/" + $(this).attr("attr-id"),
            type: "POST",
            dataType: "JSON",
            beforeSend: function () {
                action_button.addClass("hide");
                loader.addClass("showing");
            },
            success: function (response) {
                if (response.code == "200") {
                    grid.data("kendoGrid").dataSource.read();
                    grid1.data("kendoGrid").dataSource.read();
                    grid4.data("kendoGrid").dataSource.read();

                    loader.removeClass("showing");
                    action_button.removeClass("hide");
                }

                kendo.alert("Orden revertida");
            },
            error: function (response) {
                if (response.status == 401) {
                    loader.removeClass("showing");
                    action_button.removeClass("hide");
                    kendo.alert(NotAuthorized());
                }
            }
        });
    });
}

function showDocentFileContent() {
    //RadiologistView
    $("#teaching_file").click(function () {
        if ($(this).is(":checked")) {
            $("#docent-file-content").show();
        } else {
            $("#docent-file-content").hide();
        }
    });
}

function validNumCd() {
    $("#deliver_cd").click(function () {
        if ($(this).is(":checked")) {
            $("#num_cd").val(1);
        } else {
            $("#num_cd").val(0);
        }
    });
}

function validNumPlates() {
    $("#deliver_plates").click(function () {
        if ($(this).is(":checked")) {
            $("#number_of_plates").val(1);
        } else {
            $("#number_of_plates").val(0);
        }
    });
}

function deliverResults() {
    $('.deliver_results')
        .click(function () {
            if (!$('#deliver_file').is(':checked') && !$('#deliver_plates').is(':checked') && !$('#deliver_cd').is(':checked')) {
                alert('Debe escoger al menos un elemento a entregar.');
            } else {
                if ($('#number_of_plates').val() < 0) {
                    alert('El número de placas a entregar debe ser mayor o igual a 0.');
                } else {
                    if ($('#number_of_plates').val() < 1 && $('#deliver_plates').is(':checked')) {
                        alert('El número de placas a entregar debe ser mayor o igual a 1.');
                    } else {


                        if ($('#num_cd').val() < 0) {
                            alert('El número de Cd a entregar debe ser mayor o igual a 0.');
                        } else {
                            if ($('#num_cd').val() < 1 && $('#deliver_cd').is(':checked')) {
                                alert('El número de Cd a entregar debe ser mayor o igual a 1.');
                            } else {


                                $('#ResultsEditForm').submit();

                            }
                        }

                    }
                }
            }
        });
}
function closeColorBox() {

    $('#btn-pdf')
     .click(function () {
         $('#cboxClose').click();
         $("#err").remove();
     });
     $('#btn-csv')
     .click(function () {;
         $('#cboxClose').click();
         $("#err").remove();
     });
}
//MENSAJES DE ALERTA --------------------------------------------

$("#user-logout > a").click(function (e) { //Borrar mensajes alerta
    resetNoReadMessages();
});

function resetNoReadMessages() { //Borrar mensajes
    localStorage.setItem("noReadMessages", []);
}

function setNoReadMessages(messages) { //Guarda los mensajes no leidos
    localStorage.setItem("noReadMessages", JSON.stringify(messages));
}

function getNoReadMessages() { //Lee los mensajes no leidos
    return localStorage.getItem("noReadMessages");
}

function paintNumberNoReadMessages(numberNewMessages) { //Coloca en la interfaz el numero de mensajes no leidos
    if (numberNewMessages > 0) {
        $("#numberMessages").text(numberNewMessages);
        $("#numberMessages").show();
    }
}

function paintMessages(messages) { //Pinta los mensajes pasados por parámetro
    $("#notifications-container").empty();
    var templateLayout = Handlebars.compile($('#alert-message-template').html());
    var newMessages = [];

    $.each(messages, function (index, obj) {
        var newClass = "";

        if (parseInt(obj.new) == 1) {
            newMessages.push(obj.id);
            newClass = "newMessage";
        }

        var htmlLayout = templateLayout({
            cssClass: obj.alert_message_type.css_class,
            alertMessage: obj.message,
            newClass: newClass
        });
        $("#notifications-container").prepend(htmlLayout);
    });

    setNoReadMessages(newMessages);
}

function showAllMessages(url) { //Trae todos los mensajes al cargar la página
    $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        success: function (data) {
            var messages = data.messages;
            var noReadMessagesNumber = data.noReadMessages;
            paintNumberNoReadMessages(noReadMessagesNumber);
            paintMessages(messages);
        }
    });
}

// SUSPENCION ORDEN ------------------------------------

function modalSuspendOrderReason(id){
    $('#orderId').val(id);

    $('#suspensionReason').selectpicker({size: 5 })
        .selectpicker('val', '');

    $('#user-change-sel').selectpicker({size: 5, dropupAuto: false});
    ImpersonateUser.userId = null;
    ImpersonateUser.password = null;

    $('#suspensionReason-error').remove();
    $('#suspendModal').modal('show');
}

function callSuspendOrder() {
    $("#suspendForm").on("submit", function(e) {
        e.preventDefault();

        var reason = $('#suspensionReason').val();

        if( reason != '' && reason != null ) {
            if ( ImpersonateUser.canSubmit( $('#btn-add-edit-save') ) ) {
                ImpersonateUser.injectUser( $('#suspendForm') );

                var orderId = $("#orderId").val();
                $('#suspendModal').modal('hide');

                $.ajax({
                    url         : $(this).attr('action'),
                    type        : $(this).attr('method'),
                    dataType    : 'json',
                    data        : $(this).serialize(),
                    beforeSend  : function () {
                        $("#suspend-btn-"+orderId).addClass('hide');
                        $("#suspend-loader-"+orderId).addClass('showing');
                    },
                    success     : function( r ) {
                        if ( !r.id ) {
                            kendo.alert( 'Error' );
                            $("#suspend-loader-"+orderId).removeClass('showing');
                            $("#suspend-btn-"+orderId).removeClass('hide');
                        }

                        $('#grid').data('kendoGrid').dataSource.read();
                    },
                    error       : function( xhr, err ) {
                        kendo.alert("Error: " + xhr.statusText);
                        $("#suspend-loader-"+orderId).removeClass('showing');
                        $("#suspend-btn-"+orderId).removeClass('hide');
                    }
                });
            }
        }
    });

}

var dictafono = {
    data: [{
        key: 'mediris.play',
        action: 'play',
    }, {
        key: 'mediris.rec',
        action: 'rec',
        sw: false,
    }, {
        key: 'mediris.forward',
        action: 'forward',
    }, {
        key: 'mediris.rewind',
        action: 'rewind',
    }, ],
    str: '',
    init: function() {
        $(document).keydown(this.dicta.bind(this));
    },
    dicta: function(e) {
        this.str = this.str + e.key
        //console.log(this.str);
        found = false;
        for (i = 0; i < this.data.length; i++) {
            if (this.data[i]['key'].indexOf(this.str) === 0) {
                if (this.data[i]['key'].length == this.str.length) {

                    switch(this.data[i]['action']) {
                        case 'play':
                            DictationAudio.play();
                            break;
                        case 'rec':
                            if (!this.data[i]['sw'])
                                DictationAudio.startRecording();
                            else
                                DictationAudio.stopRecording();
                            this.data[i]['sw'] = !this.data[i]['sw'];
                            break;
                        case 'forward':
                            DictationAudio.forward();
                            break;
                        case 'rewind':
                            DictationAudio.rewind();
                            break;
                        default:
                            break;
                    }
                    break;
                } else {
                    found = true;
                }
            }
        }
        if (!found) {
            this.str = '';
        }
    },
};

var DictationAudio = {
    audioContext    : null,
    url             : null,
    getUserMedia    : null,
    recordButton    : null,
    stopButton      : null,
    recorder        : null,
    audio           : null,
    downloadLink    : null,
    form            : null,
    audioTagName    : 'audio_data',
    init            : function() {
        this.audioContext = window.AudioContext || window.webkitAudioContext;
        this.url = window.URL || window.webkitURL;
        this.getUserMedia = (window.navigator.getUserMedia || window.navigator.mozGetUserMedia || window.navigator.webkitGetUserMedia || window.navigator.msGetUserMedia).bind(navigator);

        this.downloadLink = $('#download-audio');
        this.recordButton = $('#record');
        this.stopButton   = $('#stop');
        this.audioElement = $('#audio');
        this.form         = $('#RadiologistEditForm');
        this.pauseButton   = $('#pause');
        this.resumenButton   = $('#resumen');

        if ( this.audioElement.length > 0 ) {
            dictafono.init();
            if ( this.getUserMedia ) {
                this.recordButton[0].disabled = false;
                this.recordButton.click(
                    this.startRecording.bind(this)
                );
                this.stopButton.click(
                    this.stopRecording.bind(this)
                );
                this.pauseButton.click(
                    this.pauseRecording.bind(this)
                );

                this.resumenButton.click(
                    this.resumenRecording.bind(this)
                );
            }
        }
    },
    startUserMedia  : function (stream) {
        this.recorder = new MediaRecorder(stream);
        this.recorder.addEventListener('dataavailable', this.onRecordingReady.bind(this) );
        this.recorder.start();
    },
    pauseRecording  : function () {
        this.recordButton[0].disabled = true;
        this.pauseButton[0].disabled = true;
        this.resumenButton[0].disabled = false;
        this.recorder.pause();
        $('#loader').hide();
    },
    resumenRecording  : function () {
        this.resumenButton[0].disabled = true;
        this.pauseButton[0].disabled = false;
        this.recorder.resume();
        $('#loader').show();
    },
    startRecording  : function () {
        $('#loader').show();
        // se elimina el elemento src del source
        this.audioElement[0].src = "";

        this.getUserMedia( {audio: true}, this.startUserMedia.bind(this), function(e){
            console.log('You are using a browser that does not support the Media Capture API');
        } );

        this.recordButton[0].disabled = true;
        this.stopButton[0].disabled = false;
        this.pauseButton[0].disabled = false;
    },
    stopRecording   : function() {
        $('#loader').hide();
        this.recordButton[0].disabled = false;
        this.stopButton[0].disabled = true;

        // this.pauseButton[0].disabled = true;
        // this.resumeButton[0].disabled = true;

        this.recorder.stop();
    },
    onRecordingReady: function( e ) {
        this.audioElement[0].src = this.url.createObjectURL(e.data);

        this.downloadLink.href = this.url.createObjectURL(e.data);
        this.downloadLink.download = new Date().toISOString() + '.flac';

        var reader = new window.FileReader();
        reader.readAsDataURL(e.data);
        reader.onloadend = function () {
            base64data = reader.result;

            if ( $('#'+this.audioTagName, this.form).length == 0 ) {
                $('<input>').attr({
                    type: 'hidden',
                    id: this.audioTagName,
                    name: this.audioTagName
                }).appendTo( this.form );
            }

            $('#'+this.audioTagName, this.form).val(base64data);
        }.bind(this);
    },
    play   : function() {
        if (this.audioElement[0].paused)
            this.audioElement[0].play();
        else
            this.audioElement[0].pause();
    },
    forward   : function() {
        this.audioElement[0].currentTime += 1;
    },
    rewind   : function() {
        this.audioElement[0].currentTime -= 1;
    },
}
